<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit('update:activated', false)"
    max-width="900"
    scrollable
  >
    <v-card>
      <v-card-title class="headline"> Selecionar Setorização: </v-card-title>
      <v-row
        align="center"
        no-gutters
      >
        <v-col cols="12">
          <v-text-field
            v-show="!loading"
            v-model="search"
            append-icon="mdi-magnify"
            class="mx-10 py-4"
            label="Procurar"
            hide-details
            single-line
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="setorizacoesComSetores"
            :items-per-page="itemsPerPage"
            :search.sync="search"
            :page.sync="currentPage"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-data-text="Nenhuma setorização disponível"
            :mobile-breakpoint="0"
          >
            <template v-slot:item="row">
              <tr @click="selecionarSetorizacao(row.item)">
                <td
                  class="button cursor-pointer"
                  style="max-width: 70px"
                >
                  {{ row.item.id }}
                </td>
                <td
                  class="button cursor-pointer"
                  style="max-width: 220px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="text-truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ row.item.nome }}
                      </div>
                    </template>
                    <span>{{ row.item.nome }}</span>
                  </v-tooltip>
                </td>
                <td
                  class="button cursor-pointer"
                  style="max-width: 120px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="text-truncate"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ row.item.algoritmo_nome }}
                      </div>
                    </template>
                    <span>{{ row.item.algoritmo_nome }}</span>
                  </v-tooltip>
                </td>
                <td class="button cursor-pointer">
                  {{ row.item.numero_setores | parseNumberToIntegerBR }}
                </td>
              </tr>
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getSetorizacoesStatusSucesso()"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar as setorizações</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import SetorizacoesService from '@/services/SetorizacoesService';

export default {
  props: {
    activated: {
      type: Boolean,
      required: true
    },
    month: {
      type: String,
      default: null
    },
    bdgdVersion: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    currentPage: 1,
    itemsPerPage: 5,
    search: undefined,
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Setorizado por',
        value: 'algoritmo_nome'
      },
      {
        text: 'Setores',
        value: 'numero_setores'
      }
    ],
    setorizacoes: [],
    loading: false
  }),

  computed: {
    dialog() {
      return this.activated;
    },
    setorizacoesComSetores() {
      return this.setorizacoes.filter(
        (setorizacao) => setorizacao.numero_setores > 0
      );
    }
  },

  methods: {
    getSetorizacoesStatusSucesso() {
      if (!(this.month && this.bdgdVersion)) return;

      this.loading = true;

      SetorizacoesService.getSetorizacoesByVersaoBdgd(
        this.month,
        this.bdgdVersion
      )
        .then((response) => (this.setorizacoes = response.data.data))
        .catch(() =>
          this.$toast.error('Erro ao recuperar as setorizações.', '', {
            position: 'topRight'
          })
        )
        .finally(() => (this.loading = false));
    },

    selecionarSetorizacao(setorizacao) {
      this.$emit('selected', setorizacao);
      setTimeout(() => (this.search = ''), 500);
    }
  },

  watch: {
    search() {
      this.currentPage = 1;
    },
    month() {
      this.getSetorizacoesStatusSucesso();
    },
    bdgdVersion() {
      this.getSetorizacoesStatusSucesso();
    }
  }
};
</script>
