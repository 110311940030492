var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900","scrollable":""},on:{"click:outside":function($event){return _vm.$emit('update:activated', false)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Selecionar Setorização: ")]),_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"mx-10 py-4",attrs:{"append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.setorizacoesComSetores,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"page":_vm.currentPage,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma setorização disponível","mobile-breakpoint":0},on:{"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',{on:{"click":function($event){return _vm.selecionarSetorizacao(row.item)}}},[_c('td',{staticClass:"button cursor-pointer",staticStyle:{"max-width":"70px"}},[_vm._v(" "+_vm._s(row.item.id)+" ")]),_c('td',{staticClass:"button cursor-pointer",staticStyle:{"max-width":"220px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(row.item.nome)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.nome))])])],1),_c('td',{staticClass:"button cursor-pointer",staticStyle:{"max-width":"120px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-truncate"},'div',attrs,false),on),[_vm._v(" "+_vm._s(row.item.algoritmo_nome)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(row.item.algoritmo_nome))])])],1),_c('td',{staticClass:"button cursor-pointer"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(row.item.numero_setores))+" ")])])]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.getSetorizacoesStatusSucesso()}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as setorizações")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }